var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-section border-top-gray",
    attrs: {
      "id": "scale-up-qna"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "title-wrap v-application align-center"
  }, [_c('div', {
    staticClass: "mb-md-16 mb-7"
  }, [_c('h3', {
    staticClass: "title-18 font-weight-regular color-primary"
  }, [_vm._v(_vm._s(_vm.code == "scale-up-qna" ? "ScaleUP" : "Super Rookie"))]), _c('h2', {
    staticClass: "title-52 font-weight-bold"
  }, [_vm._v("Q&A")])])]), _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_c('label', {
    staticStyle: {
      "margin-right": "0.5rem"
    },
    attrs: {
      "for": "subject"
    }
  }, [_vm._v("제목")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.subject,
      expression: "board.subject"
    }],
    attrs: {
      "type": "text",
      "id": "subject"
    },
    domProps: {
      "value": _vm.board.subject
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board, "subject", $event.target.value);
      }, function ($event) {
        return _vm.$emit('input', _vm.board);
      }]
    }
  })])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_c('label', {
    staticStyle: {
      "margin-right": "0.5rem"
    },
    attrs: {
      "for": "writer-name"
    }
  }, [_vm._v("작성자 이름")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.writer.name,
      expression: "board.writer.name"
    }],
    attrs: {
      "type": "text",
      "id": "writer-name"
    },
    domProps: {
      "value": _vm.board.writer.name
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board.writer, "name", $event.target.value);
      }, function ($event) {
        return _vm.$emit('input', _vm.board);
      }]
    }
  })]), _c('li', [_c('label', {
    staticStyle: {
      "margin-right": "0.5rem"
    },
    attrs: {
      "for": "password"
    }
  }, [_vm._v("비밀번호")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.password,
      expression: "board.password"
    }],
    attrs: {
      "type": "password",
      "id": "password",
      "maxlength": "6"
    },
    domProps: {
      "value": _vm.board.password
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board, "password", $event.target.value);
      }, function ($event) {
        return _vm.$emit('input', _vm.board);
      }]
    }
  })])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('naver-smarteditor', {
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.board);
      }
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)])])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "board-table board-table--preview",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" 이전글 ")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("이전 게시글 제목입니다.")])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" 이전글 ")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("다음 게시글 제목입니다.")])])])])])]), _c('div', {
    staticClass: "board-buttons"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('a', {
    staticClass: "button button--border",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', [_vm._v("리스트")])])]), _c('div', {
    staticClass: "right"
  }, [_c('a', {
    staticClass: "button button--border",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', [_vm._v("취소")])]), _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, [_c('span', [_vm._v("확인")])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }